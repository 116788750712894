import serializeParams from 'helpers/ParamsSerializeHelper';
import { genericGet } from './apiHelper';
import { RequestRestGetWithOdataParams } from 'api/RequestRestGetWithOdataParams';
import { fetchWithAuthHeader } from './AuthUtils';
import { OemId } from 'helpers/OemId';

const xClientHeader = 'repair-deck';

export const requestBooksForOemApi = async oemId => {
    const url = `api/RepairProcedure/odata/book?api-version=4.0&$filter=OemId eq ${oemId} and isDeleted eq false and isVisible eq true&$expand=BookDates,BookAssignedUsers`;

    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not retrieve books');
    let res = await response.json();
    return res.value;
};

export const getVehicleStats = async function (oemId, year, modelId) {
    const response = await fetchWithAuthHeader(
        `api/RepairProcedure/statistics/vehicle-publisher?oemId=${oemId}&year=${year}&modelId=${modelId}`
    );
    if (!response.ok) {
        throw new Error(`Failed to get vehicle stats for YMM ${year}-${oemId}-${modelId}`);
    }
    const data = await response.json();
    return {
        completedProcedures: data.completedProcedures,
        totalProcedures: data.totalProcedures,
        totalTags: data.totalTags,
        mappingStats:
            data.totalProcedures === 0
                ? '0'
                : `${data.completedProcedures} / ${data.totalProcedures} ` +
                  `(${((data.completedProcedures / data.totalProcedures) * 100).toFixed(2)}%)`,
        isCompleted: data.totalProcedures > 0 && data.totalProcedures === data.completedProcedures,
    };
};

export const requestOemHasProcedureVehicles = async oemId => {
    const url = `api/RepairProcedure/odata/ProcedureVehicle?$filter=oemId eq ${oemId}&$top=1&$count=false&$select=oemId`;

    const response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not retrieve oem vehicle count');
    const res = await response.json();
    return res.value.length > 0;
};

export const requestGetFirstProcedureVehiclesByProcedureId = async procId => {
    const url = `api/RepairProcedure/odata/ProcedureVehicle?$filter=procedureId eq ${procId}&$top=1&$count=false`;

    const response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not retrieve oem vehicle count');
    const res = await response.json();
    return Array.isArray(res.value) && res.value.length ? res.value[0] : null;
};

export const requestDeleteBookByBookId = async bookId => {
    const url = `api/RepairProcedure/odata/Book/SetRpBookIsDeleted`;
    const body = {
        RpBookId: bookId,
    };
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) throw new Error('Could not delete book');
};

export const requestProceduresByBookApi = async bookId => {
    const url =
        `api/RepairProcedure/odata/procedure?` +
        `$filter=BooksForProcedure/any(b:b/bookId eq ${bookId})&` +
        '$expand=ProcedureDetails($select=isLatest,isPublished,versionSignificance;$filter=IsLatest eq true)';
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not retrieve procedures');
    let res = await response.json();
    return res.value;
};

export const requestRemovedProceduresByBookApi = async bookId => {
    const url =
        'api/RepairProcedure/odata/procedure?' +
        `$filter=BooksForProcedure/any(b:b/bookId eq ${bookId}) and (isDeleted eq true or stageArea/isDeleted eq true)` +
        '&$expand=ProcedureDetails($select=isLatest,isPublished,versionSignificance)' +
        '&$select=procedureId,oemProcedureId,procedureVersion,isDeleted,isRemovedByOem,procedureTitle,procedureTypeId,oemId,oemIqSectionId,typeMappingWorkFlowStatusId,mappingRuleId,createDate,updateDate,stageArea,uniqueOemProceduresEnforment';
    const response = await fetchWithAuthHeader(url, {
        headers: {
            'x-client': 'none',
        },
    });
    if (!response.ok) throw new Error('Could not retrieve procedures');
    const res = await response.json();
    return res.value;
};

export const requestProceduresCountByBookIdApi = async bookId => {
    const url = `api/RepairProcedure/odata/procedure?api-version=4.0&$top=0&$count=true&$filter=BooksForProcedure/any(b:b/bookId eq ${bookId})`;

    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not retrieve procedure count');
    let res = await response.json();
    return res['@odata.count'];
};

export const requestOemHasBooks = async oemId => {
    const url = `api/RepairProcedure/odata/book?api-version=4.0&$filter=OemId eq ${oemId}`;

    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not retrieve book count');
    let res = await response.json();
    return res.value.length > 0;
};

export const requestProcedureDetailsApi = async (procedureId, isRemoved = false, withVehicles = false) => {
    let expandClause = 'ProcedureDetails($orderBy=startDate desc)';
    if (withVehicles) {
        expandClause += ',Vehicles($select=yearId,oemId,modelId,trimId)';
    }

    const url = `api/RepairProcedure/odata/procedure(${procedureId})?api-version=4.0&$expand=${expandClause}`;

    let response = await fetchWithAuthHeader(url, { headers: { 'x-client': isRemoved ? 'none' : xClientHeader } });

    if (!response.ok) throw new Error('Could not retrieve procedure details');
    return await response.json();
};

export const requestProceduresInfoByProceduresIds = async (
    proceduresIds,
    oemId,
    yearId,
    modelId,
    trimId,
    isRemoved = false
) => {
    const url = `api/RepairProcedure/odata/procedure?api-version=4.0
        &$select=ProcedureId,ProcedureTitle
        &$filter=ProcedureId in (${proceduresIds.join(',')})
        and Vehicles/any(v: v/YearId eq ${yearId} and v/OemId eq ${oemId} and v/ModelId eq ${modelId} and v/TrimId eq ${trimId})
        &$count=false`;

    let response = await fetchWithAuthHeader(url, { headers: { 'x-client': isRemoved ? 'none' : xClientHeader } });

    if (!response.ok) throw new Error('Could not retrieve procedures info');
    return await response.json();
};

export const requestProceduresInfoByOemProceduresIds = async (
    oemProceduresIds,
    oemId,
    yearId,
    modelId,
    trimId,
    isRemoved = false
) => {
    const url = `api/RepairProcedure/odata/procedure?api-version=4.0
        &$select=ProcedureId,ProcedureTitle
        &$filter=OemProcedureId in (${oemProceduresIds.join(',')})
        and Vehicles/any(v: v/YearId eq ${yearId} and v/OemId eq ${oemId} and v/ModelId eq ${modelId} and v/TrimId eq ${trimId})
        &$count=false`;

    let response = await fetchWithAuthHeader(url, { headers: { 'x-client': isRemoved ? 'none' : xClientHeader } });

    if (!response.ok) throw new Error('Could not retrieve procedures info');
    return await response.json();
};

export const requestProcedureHtmlByVersion = async (procedureId, version, isRemoved = false) => {
    const url = `api/RepairProcedure/odata/Procedure/SearchProcedureHtmlVersion`;
    const body = {
        ProcedureId: procedureId,
        Version: version,
    };

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-client': isRemoved ? 'none' : xClientHeader,
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) throw new Error(`Failed to retrieve procedure html id = ${procedureId}, version = ${version}`);
    return (await response.json()).value;
};

export const loadProcedureHtml = async (procedureId, latestProcedureDetails, isRemoved) => {
    return latestProcedureDetails && latestProcedureDetails.length
        ? await requestProcedureHtmlByVersion(procedureId, latestProcedureDetails[0].version, isRemoved)
        : '<html><body><div class="alert alert-warning mx-5 text-center" role="alert">' +
              '<h4>Procedure HTML not found!</h4><hr/>' +
              `<div class="mt-4 mb-2">No procedure version (procedure's details) for the procedureId=${procedureId} was found.` +
              ' The database and the HTML Blob Storage for that book need to be updated.</div>' +
              '</div></body></html>';
};

export const requestCreateOneTimeUseTag = async tags => {
    const url = 'api/RepairProcedure/odata/StagedOneTimeUseTags/CreateTag';
    let body = { createStagedOneTimeUseTags: tags };

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) throw new Error('Could not create new one time use tag');
    const res = await response.json();
    return res.value;
};

export const requestQuantityConditions = async () => {
    const url = 'api/RepairProcedure/odata/QuantityConditions?$count=false';
    const response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not get quantity conditions');
    const res = await response.json();
    return res.value;
};

export const requestUpdateRegionStatusApi = async (procedureIds, regionIds, statusId, oemId, oDataFilter) => {
    const url = 'api/RepairProcedure/odata/ProcedureRegion/UpdateStatus';
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            regionIds: regionIds,
            procedureIds: procedureIds,
            statusId: statusId,
            oemId: oemId,
            oDataFilter: oDataFilter,
        }),
    });
    if (!response.ok) throw new Error('Could not update mapping status');
    const res = await response.json();
    return { procedureIds: res.value };
};

export const requestMapProcedureGroupApi = async (procedureIds, regionIds, oemId, oDataFilter) => {
    const url = 'api/RepairProcedure/odata/ProcedureRegion/MapProcedures';
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            procedureIds: procedureIds,
            regionIds: regionIds,
            oemId: oemId,
            oDataFilter: oDataFilter,
        }),
    });
    if (!response.ok) throw new Error('Could not map procedure');
    const res = await response.json();
    return { procedureIds: res.value };
};

export const requestRemoveMappingApi = async (procedureIds, regionIds, oemId, oDataFilter) => {
    const url = 'api/RepairProcedure/odata/ProcedureRegion/UnmapProcedures';
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            procedureIds: procedureIds,
            regionIds: regionIds,
            oemId: oemId,
            oDataFilter: oDataFilter,
        }),
    });
    if (!response.ok) throw new Error('Could not delete mapping status');
    const res = await response.json();
    return { procedureIds: res.value };
};

export const requestMappingStatistics = async bookId => {
    const url = `api/RepairProcedure/statistics/books/${bookId}/mapper`;
    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
    });

    if (!response.ok) throw new Error('Could not get mapping statistics');

    return await response.json();
};

export const requestRefreshPublisherStatistics = async bookId => {
    const url = `api/RepairProcedure/statistics/books/${bookId}/refresh-publisher`;
    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
    });

    if (!response.ok) throw new Error('Could not get refresh publisher statistics');

    return await response.json();
};

export const requestGetTagsApi = async bookId => {
    const url = `api/RepairProcedure/odata/StagedOneTimeUseTags?api-version=4.0&$filter=Procedure/BooksForProcedure/any(b:b/bookId eq ${bookId})&$expand=WorkFlowStatus,OneTimeUsePartType,Procedure($expand=Type,Groups,ProcedureDetails($select=isLatest,isPublished,versionSignificance)),OneTimeUseTagElements,OneTimeUseTagImages`;
    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
    });
    if (!response.ok) throw new Error('Could not get tags');
    let res = await response.json();
    return res.value;
};

export const requestOemBooksTaggerStats = async oemId => {
    let url = `api/RepairProcedure/${oemId}/books/statistics/tagger`;
    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
    });
    if (!response.ok) throw new Error('Could not get tagger statistics');

    return await response.json();
};

export const requestBookTaggerStats = async bookId => {
    let url = `api/RepairProcedure/statistics/books/${bookId}/tagger`;
    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
    });
    if (!response.ok) throw new Error('Could not get tagger statistics');

    return await response.json();
};

export const requestRefreshPublisherBook = async (oemId, bookId) => {
    let url = `api/RepairProcedure/${oemId}/refresh-publisher/${bookId}`;
    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
    });
    if (!response.ok) throw new Error('Could not get refresh publisher book');

    return await response.json();
};

export const requestGetTagById = async tagId => {
    const url = `api/RepairProcedure/odata/StagedOneTimeUseTags(${tagId})?api-version=4.0&$expand=Procedure,ProcedureDetail,OneTimeUsePartType,OneTimeUseTagElements,OneTimeUseTagImages,WorkFlowStatus,OneTimeUsePartType,OneTimeUseFlag`;

    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
    });
    if (!response.ok) throw new Error('Could not get tags');
    let res = await response.json();
    return res;
};

export const requestGetFlagsApi = async bookId => {
    const url = `api/RepairProcedure/odata/OneTimeUseFlags?api-version=4.0&$filter=Procedure/BooksForProcedure/any(b:b/bookId eq ${bookId})&$expand=Procedure($expand=ProcedureDetails($select=isLatest,isPublished,versionSignificance))`;

    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
    });
    if (!response.ok) throw new Error('Could not get flags');
    let res = await response.json();
    return res.value;
};

export const requestUpdateOneTimeUseTag = async tags => {
    const url = 'api/RepairProcedure/odata/StagedOneTimeUseTags/UpdateTag';
    const body = { updateStagedOneTimeUseTags: tags };

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) throw new Error('Could not update tags');
    const res = await response.json();
    return res;
};

/*
   List<FlagDisposition> { OneTimeUseFlagId, OneTimeUseFlagDispositionId }
*/
export const requestUpdateFlagDisposition = async flags => {
    const url = 'api/RepairProcedure/odata/OneTimeUseFlags/UpdateFlagDisposition';

    let body = { Flags: flags };

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) throw new Error('Could not update flag disposition');
};

const sanitiveCssSelector = selector => selector.replaceAll(/(?<=\s|^)((html)|(body))(\s*>)?/gi, '');
const fixIntegerId = selector => selector.replaceAll(/#[0-9]+\w*/g, match => `[id="${match.substring(1)}"]`);

export const requestGetTagsByProcedureIdApi = async procedureId => {
    const url = `api/RepairProcedure/odata/StagedOneTimeUseTags?api-version=4.0&$filter=Procedure/ProcedureId eq ${procedureId}&$expand=ProcedureDetail,WorkFlowStatus,OneTimeUseTagElements,OneTimeUseTagImages,OneTimeUsePartType,OneTimeUseFlag`;

    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
    });
    if (!response.ok) throw new Error('Could not get tags');
    let res = await response.json();

    return res.value.map(tag => {
        for (const el of tag.oneTimeUseTagElements) {
            el.contentStart = fixIntegerId(sanitiveCssSelector(el.contentStart));
            el.contentEnd = fixIntegerId(sanitiveCssSelector(el.contentEnd));
        }
        return tag;
    });
};

export const requestGetFlagsByProcedureIdApi = async procedureId => {
    const url = `api/RepairProcedure/odata/OneTimeUseFlags?api-version=4.0&$filter=Procedure/ProcedureId eq ${procedureId}&$expand=WorkFlowStatus,OneTimeUseFlagElement,OneTimeUseFlagImage,FlagDisposition,PartType`;

    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
    });
    if (!response.ok) throw new Error('Could not get flags');
    let res = await response.json();

    return res.value.map(flag => {
        if (flag.oneTimeUseFlagElement) {
            flag.oneTimeUseFlagElement.contentStart = fixIntegerId(
                sanitiveCssSelector(flag.oneTimeUseFlagElement.contentStart)
            );
            flag.oneTimeUseFlagElement.contentEnd = fixIntegerId(
                sanitiveCssSelector(flag.oneTimeUseFlagElement.contentEnd)
            );
        }
        return flag;
    });
};

export const requestSearchProcedures = async bookQuery => {
    const url = 'api/RepairProcedure/odata/Search/SearchBook';
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bookQuery),
    });
    if (!response.ok) throw new Error('Failed to search');
    let data = await response.json();

    return data.procedures;
};

export const requestGetTagLinks = async linkedGuid => {
    const url = `api/RepairProcedure/odata/StagedOneTimeUseTags?api-version=4.0&$filter=OneTimeUseTagLink eq ${linkedGuid}`;

    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
    });
    if (!response.ok) throw new Error('Failed to get tag links');
    let res = await response.json();
    return res.value;
};

export const requestLinkTagNameMatchesInBook = async (tagTitle, bookId) => {
    const url = `api/RepairProcedure/odata/StagedOneTimeUseTags?api-version=4.0&$filter=title eq '${tagTitle}' and procedure/booksForProcedure/any(b:b/bookId eq ${bookId})&$expand=Procedure`;

    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
    });
    if (!response.ok) throw new Error('Failed to get tag matches for name');
    let res = await response.json();
    return res.value;
};

export const requestLinkTagTextMatchesInBook = async (tagText, bookId) => {
    const url = `api/RepairProcedure/odata/StagedOneTimeUseTags?api-version=4.0&$filter=text eq '${tagText}' and procedure/booksForProcedure/any(b:b/bookId eq ${bookId})&$expand=Procedure`;

    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
    });
    if (!response.ok) throw new Error('Failed to get tag matches for text');
    let res = await response.json();
    return res.value;
};

export const checkLinkOrphans = async bulkCheckLinkOneTimeCommand => {
    // uses the link command if ok, else sends warning
    const url = 'api/RepairProcedure/odata/StagedOneTimeUseTags/BulkCheckLinkTags';
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bulkCheckLinkOneTimeCommand),
    });
    if (!response.ok) throw new Error('Failed to check for orphaned tags');
    let res = await response.json();
    return res.value;
};

export const requestGetTagsForCSVByBook = async (oemName, bookId) => {
    const url = `api/RepairProcedure/GetTagsForCSVByBook/${oemName}/${bookId}`;
    const response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Failed to get tags for CSV');
    return response.json();
};

export const requestGetOemiqSections = async () => {
    const url = `api/RepairProcedure/odata/OemIqSection`;

    const response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Failed to get type sections');
    let res = await response.json();
    return res.value;
};

export const requestMapProceduresToType = async (oemIqSectionId, procedureIds, statusId, oemId, oDataFilter) => {
    const request = {
        oemIqSectionId: oemIqSectionId,
        procedureIds: procedureIds,
        typeStatusId: statusId,
        oemId,
        oDataFilter,
    };

    const url = 'api/RepairProcedure/odata/OemIqSection/MapOemIqSection';
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
    });
    if (!response.ok) throw new Error('Failed to map procedure to section');
    const res = await response.json();
    return { procedureIds: res.value };
};

export const requestUpdateTypeStatus = async (procedureIds, typeStatusId) => {
    const request = {
        procedureIds: procedureIds,
        typeStatusId: typeStatusId,
    };

    const url = 'api/RepairProcedure/odata/OemIqSection/UpdateTypeStatus';
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
    });
    if (!response.ok) throw new Error('Failed to map procedure to section');
};

export const requestUnmapType = async (procedureIds, typeId, oemId, oDataFilter) => {
    const request = {
        procedureIds: procedureIds,
        typeId,
        oemId,
        oDataFilter,
    };

    const url = 'api/RepairProcedure/odata/OemIqSection/UnmapType';
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
    });

    if (!response.ok) throw new Error('Failed to unmap type');
    const res = await response.json();
    return { procedureIds: res.value };
};

export const requestGraphDataNodes = async bookId => {
    const url = `api/RepairProcedure/odata/Procedure?api-version=4.0&$filter=BooksForProcedure/any(b:b/bookId eq ${bookId})`;

    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
    if (!response.ok) throw new Error('Failed to get graph data');
    let value = await response.json();
    return value;
};

export const requestGraphDataLinks = async bookId => {
    const url = `api/RepairProcedure/odata/SubProcedure?api-version=4.0&$filter=ParentProcedure/BooksForProcedure/any(b: b/bookId eq ${bookId})`;

    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
    if (!response.ok) throw new Error('Failed to get graph data');
    const value = await response.json();
    return value;
};

export const requestPositionStatements = async oemId => {
    const positionStatementGroupId = 40;
    const url = `api/RepairProcedure/odata/Procedure?api-version=4.0&$expand=Vehicles,Groups,Type&$filter=Groups/any(r: r/regionId eq ${positionStatementGroupId}) and Vehicles/any(v:v/OemId eq ${oemId}) and ProcedureTypeId eq 2`;

    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
    if (!response.ok) throw new Error('Failed to get position statement data');
    const res = await response.json();
    return res.value;
};

export const requestSavePositionStatement = async (oemId, formData) => {
    const url = `api/RepairProcedure/odata/Procedure/CreatePositionStatement`;
    formData.append('OemId', oemId.toString());
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        body: formData,
        headers: {
            Accept: 'application/json',
        },
    });
    if (!response.ok) throw new Error('Failed to post position statement');
};

export const requestDeletePositionStatement = async procedureId => {
    const url = 'api/RepairProcedure/odata/Procedure/RemovePositionStatement';
    const body = {
        ProcedureId: procedureId,
    };

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) throw new Error('Failed to update position statement');
};

export const createTrainingMappingBookApi = async (bookId, bookTitle) => {
    const url = 'api/RepairProcedure/odata/Book/CreateTrainingBook';
    const body = {
        bookId: bookId,
        bookTitle: bookTitle,
    };

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) throw new Error('Failed to create training book');
};

export const requestUpdatePositionStatement = async (oemId, formData) => {
    const url = `api/RepairProcedure/odata/Procedure/UpdatePositionStatement`;
    formData.append('OemId', oemId.toString());
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        body: formData,
        headers: {
            Accept: 'application/json',
        },
    });
    if (!response.ok) throw new Error('Failed to update position statement');
};

export const requestBooksByVehicle = async (yearId, oemId, modelId, trimId) => {
    const url = `api/RepairProcedure/odata/book?api-version=4.0&$expand=BookAssignedUsers&$filter=ProceduresForBook/any(pb:pb/Procedure/Vehicles/any(v:v/YearId eq ${yearId} and v/OemId eq ${oemId} and v/ModelId eq ${modelId} and v/TrimId eq ${trimId}))`;

    const response = await fetchWithAuthHeader(url, {
        headers: {
            Accept: 'Application/json',
        },
    });
    if (!response.ok) throw new Error('Cannot get books for vehicle');

    const res = await response.json();
    return res.value;
};

export const requestImportJobForOem = async (oemId, top = 20, skip = 0, searchValue = null) => {
    let searchFilter = '';
    if (searchValue) {
        const fields = ['importJobId', 'numberOfBooks', 'success', 'createDate', 'updateDate'];
        searchFilter = ` and (${fields
            .map(field => `contains(cast(${field}, Edm.String), '${searchValue}')`)
            .join(' or ')})`;
    }

    const buildUrl = bookOptions =>
        `api/RepairProcedure/odata/ImportJob?$filter=oemId eq ${oemId}${searchFilter}&$expand=books(${bookOptions}),importJobDownloadType&$top=${top}&$skip=${skip}&$orderBy=createDate desc&$count=true&api-version=4.0`;

    const fetchData = async (url, errorMsg = 'Fail to fetch data') => {
        try {
            const response = await fetchWithAuthHeader(url);
            if (!response.ok) {
                throw new Error(
                    `${errorMsg}: Server responded with status ${response.status} (${response.statusText})`
                );
            }
            return await response.json();
        } catch (error) {
            throw new Error(`${errorMsg}: ${error.message}`);
        }
    };

    // Get total number of books to publish to ensure consistency
    const urlBooksToPublish = buildUrl('$count=true;$top=0;');
    const urlErrors = buildUrl('$count=true;$top=0;$filter=success eq false');
    const urlPublishedBooks = buildUrl('$count=true;$top=0;$filter=publishDate ne null');

    const contentBooksToPublish = await fetchData(
        urlBooksToPublish,
        'Cannot get importJobs with all associated books count'
    );
    const contentErrors = await fetchData(urlErrors, 'Cannot get importJobs with errors count');
    const contentPublishedBooks = await fetchData(
        urlPublishedBooks,
        'Cannot get importJobs with published books count'
    );

    // Combine the odata counts into the returning content
    contentBooksToPublish.value.forEach((item, index) => {
        item.numToPublish = item['books@odata.count'];
        item.errors = contentErrors.value[index]['books@odata.count'];
        item.numOfPublished = contentPublishedBooks.value[index]['books@odata.count'];
        delete item['books@odata.count'];
    });

    return contentBooksToPublish;
};

export const requestImportJobHistoryByOemId = async (oemId, limit = 5) => {
    let url = `api/RepairProcedure/odata/ImportJob/GetImportJobDetails(OemId=${oemId},Limit=${limit})`;

    const response = await fetchWithAuthHeader(url, {
        headers: {
            Accept: 'Application/json',
        },
    });
    if (!response.ok) throw new Error('Cannot get books for vehicle');

    const res = await response.json();
    return res;
};

export const requestImportJobDetails = async (
    importJobId,
    top = 20,
    skip = 0,
    searchValue = null,
    successFilter = null
) => {
    const searchValueFilter = searchValue ? `(contains(bookFriendlyName,'${searchValue}'))` : '';
    const sucessFilter = successFilter === 'true' || successFilter === 'false' ? `success eq ${successFilter}` : '';
    const filters = [searchValueFilter, sucessFilter].filter(f => f).join(' and ');
    const bookFilter = filters ? `$filter=${filters}` : '';
    const url = `api/RepairProcedure/odata/ImportJob?$expand=Books($top=${top};$skip=${skip};$count=true;$orderBy=createDate desc;${bookFilter})&$filter=ImportJobId eq ${importJobId}`;

    const response = await fetchWithAuthHeader(url, {
        headers: {
            Accept: 'Application/json',
        },
    });
    if (!response.ok) throw new Error(`Cannot get import job details for import job id ${importJobId}`);

    const data = await response.json();
    return data.value;
};

export const requestRetryImportJob = async (importJobId, shouldReCrawl, shouldReImport, shouldReImportAll, force) => {
    const url = 'api/RepairProcedure/odata/ImportJob/RetryImportJob';

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'Application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            importJobId: importJobId,
            shouldReCrawl: shouldReCrawl,
            shouldReImport: shouldReImport,
            shouldReImportAll: shouldReImportAll,
            force: force,
        }),
    });
    if (!response.ok) {
        throw new Error(`Could not retry import job id ${importJobId}`);
    }
};

export const requestImportJobBookSummary = async importJobId => {
    const url = `api/RepairProcedure/odata/ImportJob/${importJobId}/ImportJobBookSummary`;
    const response = await fetchWithAuthHeader(url, {
        headers: {
            Accept: 'application/json',
        },
    });
    if (!response.ok) throw new Error(`Failed to get import job book summary for import job id ${importJobId}`);
    const summary = await response.json();
    return summary;
};

export const requestForceImportJobComplete = async importJobId => {
    const url = 'api/RepairProcedure/odata/ImportJob/ForceImportJobComplete';
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'Application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            importJobId: importJobId,
        }),
    });

    if (!response.ok) throw new Error(`Failed to force complete job for import job id ${importJobId}`);
};

/**
 * Get untagged flags by oem
 * @param {int} oemId oem id
 * @param {int} skip number of untagged flags to skip - pagination param
 * @param {int} top number of untagged flags to fetch - pagination param
 * @param {string} filter filter term that appends to the odata call
 * @param {string[]} orderby list of column name and sort order terms
 * @returns object contains value and count.
 *         value: array of untagged flags
 *         total: total untagged flags
 */

export const getUntaggedFlagsByOemAsync_pagination = async (oemId, { skip, top, modelIds, filter, orderBy }) => {
    const filterSegment = filter ? encodeURIComponent(filter) : '';
    const modelsQuery = modelIds ? modelIds.map(modelId => `v/modelId eq ${modelId}`).join(' or ') : '';
    const filterModels = modelsQuery ? ` and Procedure/vehicles/any(v:${modelsQuery})` : '';
    const orderBySegment =
        orderBy && orderBy.length > 0 ? `&$orderBy=${orderBy.join(',')}` : '&$orderBy=OneTimeUseFlagId desc';
    const url =
        '/api/RepairProcedure/odata/OneTimeUseFlags?api-version=4.0' +
        '&splitQueries=false' +
        `&$top=${top}&$skip=${skip}&$count=true` +
        '&$expand=procedure($select=procedureTitle,stageArea;$expand=booksForProcedure($select=bookId;$expand=book($select=bookName)))' +
        /* - */ ',oneTimeUseFlagTerm,oneTimeUseFlagElement' +
        `&$filter=oemId eq ${oemId}` +
        /* - */ ' and not hasTag' +
        filterSegment +
        filterModels +
        orderBySegment;

    const response = await fetchWithAuthHeader(url, {
        headers: {
            Accept: 'Application/json',
        },
    });
    if (!response.ok) throw new Error(`Cannot get untagged flags for oemId = ${oemId}`);
    const data = await response.json();
    return { total: data['@odata.count'], value: data.value };
};

/**
 * Get tags by oem
 * @param {int} oemId oem id
 * @param {int} skip number of tags to skip - pagination param
 * @param {int} top number of tags to fetch - pagination param
 * @param {string} filter filter term that appends to the odata call
 * @param {string[]} orderby list of column name and sort order terms
 * @returns object contains value and count.
 *         value: array of tags
 *         total: total tags
 */
export const getTagsByOemAsync_pagination = async (oemId, { skip, top, modelIds, filter, orderBy }) => {
    const filterSegment = filter ? encodeURIComponent(filter) : '';
    const modelsQuery =
        modelIds && modelIds.length > 0 ? modelIds.map(modelId => `v/modelId eq ${modelId}`).join(' or ') : '';
    const filterModels = modelsQuery ? ` and Procedure/vehicles/any(v:${modelsQuery})` : '';
    const orderBySegment =
        orderBy && orderBy.length > 0 ? `&$orderBy=${orderBy.join(',')}` : '&$orderBy=OneTimeUseTagId desc';
    const url =
        '/api/RepairProcedure/odata/StagedOneTimeUseTags?api-version=4.0' +
        '&splitQueries=false' +
        `&$top=${top}&$skip=${skip}&$count=true` +
        '&$expand=procedure($select=procedureTitle,stageArea;$expand=booksForProcedure($select=bookId;$expand=book($select=bookName)))' +
        /* - */ ',oneTimeUsePartType,OneTimeUseTagImages($count=true;$top=0),procedureDetail' +
        `&$filter=oemId eq ${oemId} ` +
        filterSegment +
        filterModels +
        orderBySegment;

    const response = await fetchWithAuthHeader(url, {
        headers: {
            Accept: 'Application/json',
        },
    });
    if (!response.ok) throw new Error(`Cannot get untagged flags for oemId = ${oemId}`);
    const data = await response.json();
    return { total: data['@odata.count'], value: data.value };
};

export const updateFlagNeedsAttention = async flagsArray => {
    const url = 'api/RepairProcedure/odata/OneTimeUseFlags/UpdateFlagNeedsAttention';
    const body = { flags: flagsArray };
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) throw new Error('Failed to update flags needs attention property');
};

/**
 * Get OneTimeUseFlagDisposition type
 * @returns an array of OneTimeUseFlagDisposition type
 */
export const getOneTimeUseFlagDispositionTypes = async () => {
    const url = 'api/RepairProcedure/odata/OneTimeUseFlagDispositions';
    const response = await fetchWithAuthHeader(url);
    if (!response.ok) {
        throw new Error('Failed to get OneTimeUseFlagDisposition types');
    }
    const data = await response.json();
    return data.value;
};

export const requestPublishBooksAndRemovePendingProcedures = async bookIds => {
    const url = 'api/RepairProcedure/procedurePublisher/publishBooksAndRemovePendingProcedures';
    let body = { bookIds };

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) throw new Error(`Could not publish procedure for book ${bookIds}`);
    return response.ok;
};

export const requestGetProcedureMappingHistory = async (procedureId, isRemoved = false) => {
    const params = {
        $orderby: 'procedureMappingHistoryId desc',
    };
    const url = `/api/RepairProcedure/odata/ProcedureMappingHistory/GetProcedureMappingHistory(procedureId=${procedureId})?${serializeParams(
        params
    )}`;
    const response = await fetchWithAuthHeader(url, { headers: { 'x-client': isRemoved ? 'none' : xClientHeader } });
    if (!response.ok) throw new Error('Could not retrieve history data for procedure');
    let res = await response.json();
    return res.value;
};

export const requestUpdateProcedureMappingStatus = async (procedureIds, mappingStatusId, oemId, oDataFilter) => {
    const url = `api/RepairProcedure/odata/procedure/UpdateMappingStatus`;
    const body = { procedureIds, mappingStatusId, oemId, oDataFilter };

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) throw new Error('Failed to update procedure mapping status');
    const res = await response.json();
    return res;
};

export const requestBulkTaskHistory = async (oemId, top) => {
    const url =
        `api/RepairProcedure/odata/ProcedureMappingTask?api-version=4.0` +
        `&$select=procedureMappingTaskId,userId,procedureMappingTaskProcedures($top=0;$count=true),createDate` +
        `&$expand=taskStatus,taskType` +
        `&$filter=oemId eq ${oemId}` +
        `&$top=${top}` +
        `&$orderBy=createDate desc`;

    const response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error(`Failed to get bulk action history for oem ${oemId}`);
    let res = await response.json();
    return res.value;
};

/**
 * To get tagger statistics of a given vehicle
 * @param {int} yearId year id
 * @param {int} oemId oem id
 * @param {int} modelId model id
 * @param {int?} trimId trim id
 * @returns tagger statistics
 */
export const requestTaggerStatisticsForVehicle = async (yearId, oemId, modelId, trimId) => {
    let url = `api/RepairProcedure/statistics/tagger/vehicle?yearId=${yearId}&oemId=${oemId}&modelId=${modelId}`;
    if (trimId) {
        url += `&trimId=${trimId}`;
    }
    const response = await fetchWithAuthHeader(url);
    if (!response.ok)
        throw new Error(`Failed to get tagger stats for vehicle YMMT ${yearId} ${oemId} ${modelId} ${trimId}`);
    let res = await response.json();
    return res;
};

export const requestRefreshOem = async (oemId, downloadTypeId) => {
    const url = 'api/RepairProcedure/odata/Importer/RefreshOem';
    const payload = { oemId, downloadTypeId };
    const body = JSON.stringify(payload);
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: body,
    });

    if (!response.ok) {
        const content = await response.json();
        throw new Error(content.value);
    }
};

export const requestReimportBook = async importJobBookId => {
    const url = 'api/RepairProcedure/odata/ImportJobBook/Requeue';
    const body = JSON.stringify({ importJobBookId });

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: body,
    });

    if (!response.ok) {
        throw new Error(`Failed to reimport book ${body}`);
    }
};

export const requestSerializedImportJobCommand = async importJobBookId => {
    const url = `api/RepairProcedure/odata/ImportJobBook/GetSerializedCommand(importJobBookId=${importJobBookId})`;
    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
    if (!response.ok) throw new Error('Failed to fetch Serialized Import Job Book Command');
    return await response.json();
};

export const requestIsOperationsAreRunning = async () => {
    const url = "api/RepairProcedure/odata/Operation?$filter=status eq 'Running'&$top=0&$count=true";
    const response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not check if operations are running');
    const res = await response.json();
    return !!res['@odata.count'];
};

export const requestFordScrapeManagerVehicle = async () =>
    await genericGet(
        'api/RepairProcedure/odata/FordScrapeManagerVehicle?$top=100&$count=false',
        'Could not retrieve vehicles'
    );

export const requestFordScrapeManagerVehicleOdata = async odataInfo => {
    const url = 'api/RepairProcedure/odata/FordScrapeManagerVehicle';
    const result = await RequestRestGetWithOdataParams(url, odataInfo);
    return result;
};

export const requestGMCImportPublication = async () =>
    await genericGet('api/RepairProcedure/odata/GmImportPublication', 'Could not retrieve vehicles');

export const requestGMCImportPublicationOdata = async odataInfo => {
    const url = 'api/RepairProcedure/odata/GmImportPublication';
    const result = await RequestRestGetWithOdataParams(url, odataInfo);
    return result;
};

export const requestFordSetShouldVehicleBeRun = async (
    fordScrapeManagerVehicleId,
    shouldVehicleBeRun,
    hasBeenReviewed
) => {
    const url = 'api/RepairProcedure/odata/FordScrapeManagerVehicle/SetShouldVehicleBeRun';
    const body = { fordScrapeManagerVehicleId, shouldVehicleBeRun, hasBeenReviewed };

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) throw new Error('Failed to update Ford review status');
};

export const requestFordBulkSetShouldVehicleBeRun = async (ids, shouldVehicleBeRun, hasBeenReviewed) => {
    const url = 'api/RepairProcedure/odata/FordScrapeManagerVehicle/BulkSetShouldVehicleBeRun';
    const body = ids.map(id => ({
        fordScrapeManagerVehicleId: id,
        shouldVehicleBeRun,
        hasBeenReviewed,
    }));

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) throw new Error('Failed to update Ford review status');
};

export const requestFordSetNotes = async (id, notes) => {
    const url = 'api/RepairProcedure/odata/FordScrapeManagerVehicle/SetNotes';
    const body = { vehicle: { fordScrapeManagerVehicleId: id, notes: notes } };

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) throw new Error('Failed to update Ford notes');
};

export const requestGMSetShouldVehicleBeRun = async (importPublicationId, shouldBeRun, hasBeenReviewed) => {
    const url = 'api/RepairProcedure/odata/GmImportPublication/SetShouldVehicleBeRun';
    const body = { importPublicationId, shouldBeRun, hasBeenReviewed };

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) throw new Error('Failed to update GM review status');
};

export const requestGMBulkSetShouldVehicleBeRun = async (ids, shouldBeRun, hasBeenReviewed) => {
    const url = 'api/RepairProcedure/odata/GmImportPublication/BulkSetShouldVehicleBeRun';
    const body = ids.map(id => ({
        importPublicationId: id,
        shouldBeRun,
        hasBeenReviewed,
    }));

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) throw new Error('Failed to update GM review status');
};

export const requestImportJobDownloadType = async () => {
    const url = 'api/RepairProcedure/odata/ImportJobDownloadType';
    const response = await fetchWithAuthHeader(url);
    if (!response.ok) {
        throw new Error('Failed to get ImportJobDownloadType');
    }
    return (await response.json()).value;
};

export const requestFlagDispositionRules = async oemId => {
    const url = `api/RepairProcedure/odata/FlagDispositionRules?$filter=OemId eq ${oemId}&$expand=FlagDispositionRuleDefinitions, OneTimeUseFlagDisposition`;
    const response = await fetchWithAuthHeader(url);

    if (!response.ok) {
        throw new Error('Failed to get FlagDispositionRules');
    }
    return (await response.json()).value;
};

export const requestHuyndaiVehicleScrapedManager = async () =>
    await genericGet(
        'api/RepairProcedure/odata/HyundaiScrapedVehicles?$top=100&$count=false',
        'Could not retrieve vehicles'
    );

export const requestHyundaiVehicleScrapeManagerOdata = async odataInfo => {
    const url = 'api/RepairProcedure/odata/HyundaiScrapedVehicles';
    const result = await RequestRestGetWithOdataParams(url, odataInfo);
    return result;
};

export const requestHyundaiBulkSetShouldVehicleBeRun = async (ids, shouldBeRun, hasBeenReviewed) => {
    const url = 'api/RepairProcedure/odata/HyundaiScrapedVehicles/ShouldBeRun';
    const body = {
        vehicles: ids.map(id => ({
            id: id,
            shouldBeRun: shouldBeRun,
            hasBeenReviewed: hasBeenReviewed,
        })),
    };

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) throw new Error('Failed to update Hyundai review status');
};

export const requestHyundaiSetNotes = async (id, notes) => {
    const url = 'api/RepairProcedure/odata/HyundaiScrapedVehicles/SetNotes';
    const body = { vehicle: { id: id, notes: notes } };

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) throw new Error('Failed to update Hyundai notes');
};

export const requestGenesisVehicleScrapedManager = async () =>
    await genericGet(
        'api/RepairProcedure/odata/GenesisScrapedVehicles?$top=100&$count=false',
        'Could not retrieve vehicles'
    );

export const requestGenesisVehicleScrapeManagerOdata = async odataInfo => {
    const url = 'api/RepairProcedure/odata/GenesisScrapedVehicles';
    const result = await RequestRestGetWithOdataParams(url, odataInfo);
    return result;
};

export const requestGenesisBulkSetShouldVehicleBeRun = async (ids, shouldBeRun, hasBeenReviewed) => {
    const url = 'api/RepairProcedure/odata/GenesisScrapedVehicles/ShouldBeRun';
    const body = {
        vehicles: ids.map(id => ({
            id: id,
            shouldBeRun: shouldBeRun,
            hasBeenReviewed: hasBeenReviewed,
        })),
    };

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) throw new Error('Failed to update Genesis review status');
};

export const requestGenesisSetNotes = async (id, notes) => {
    const url = 'api/RepairProcedure/odata/GenesisScrapedVehicles/SetNotes';
    const body = { vehicle: { id: id, notes: notes } };

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) throw new Error('Failed to update Genesis notes');
};

export const requestGMSetNotes = async (importPublicationId, notes) => {
    const url = 'api/RepairProcedure/odata/GmImportPublication/SetNotes';
    const body = { importPublicationId: importPublicationId, notes: notes };

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) throw new Error('Failed to update GM notes');
};

const getVehicleCount = async url => {
    const response = await fetchWithAuthHeader(url);
    if (!response.ok) {
        throw new Error(`Failed to fetch data from ${url}`);
    }
    const data = await response.json();
    return data['@odata.count'];
};

export const requestNewVehicleCounts = async () => {
    const urls = {
        [OemId.Ford]: `api/RepairProcedure/odata/FordScrapeManagerVehicle?$top=0&$count=true&$filter=hasBeenReviewed eq false and isDeleted eq false`,
        [OemId.GMC]: `api/RepairProcedure/odata/GmImportPublication?$top=0&$count=true&$filter=hasBeenReviewed eq false`,
        [OemId.Hyundai]: `api/RepairProcedure/odata/HyundaiScrapedVehicles?$top=0&$count=true&$filter=hasBeenReviewed eq false and isDeleted eq false`,
        [OemId.Genesis]: `api/RepairProcedure/odata/GenesisScrapedVehicles?$top=0&$count=true&$filter=hasBeenReviewed eq false and isDeleted eq false`,
    };

    const counts = await Promise.all(
        Object.entries(urls).map(async ([oemId, url]) => {
            const count = await getVehicleCount(url);
            return { oemId: Number(oemId), count };
        })
    );

    const total = counts.reduce((sum, { count }) => sum + count, 0);

    const result = counts.reduce((acc, { oemId, count }) => {
        acc[oemId] = { newVehicleCount: count };
        return acc;
    }, {});

    result.total = total;

    return result;
};

export const requestActiveMessageCount = async topicName => {
    const url = `api/RepairProcedure/messagestream/count/active?topicName=${topicName}`;
    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Failed to fetch active message count');
    }

    const result = await response.json();
    return result;
};

export const requestActiveFlagsCount = async () => {
    const url = 'api/RepairProcedure/odata/OneTimeUseFlags/GetActiveFlagsCount';
    const response = await fetchWithAuthHeader(url);

    if (!response.ok) {
        throw new Error('Could not retrieve active flags count');
    }

    const result = await response.json();
    return result;
};

export const requestRequeueBookForFlagging = async command => {
    const url = 'api/RepairProcedure/odata/OneTimeUseFlags/QueueBook';

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(command),
    });

    if (!response.ok) {
        throw new Error('Could not requeue book for flagging');
    }
};
